import React from 'react'

import { Badge, Blockquote, Paragraph } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Blockquote"
    components={[{ component: Blockquote }]}
  >
    <Section>
      <Paragraph>
        <Badge>20px, 400</Badge>
      </Paragraph>
      <Playground>
        <Blockquote>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere
          interdum sem.
        </Blockquote>
      </Playground>
      <Playground format="html">
        <>
          <div className="divider" />
          <blockquote className="blockquote">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
            posuere interdum sem.
          </blockquote>
          <div className="divider" />
        </>
      </Playground>
    </Section>
  </Content>
)

export default Page
